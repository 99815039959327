import quantize from '@lokesh.dhakar/quantize'

interface PaletteOptions {
  colorCount: number
  quality: number
}

type Color = [number, number, number]

// NOTE: Copied and modified because `colorthief` doesn't allow tree-shaking.
//
// See:
//   https://github.com/lokesh/color-thief/blob/4dc3bb0b43250f0d006278998aa91c4156f77cc3/src/color-thief.js#L80
export function getPalette (sourceImage: HTMLImageElement, options?: PaletteOptions) {
  const pixelArray = getImagePixelArray(sourceImage, options?.quality)

  // Send array to quantize function which clusters values
  // using median cut algorithm
  const cmap = quantize(pixelArray, options?.colorCount || 5)
  return cmap?.palette() as Color[]
}

function getImagePixelArray (image: HTMLImageElement, quality: number = 10) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')!
  const width = canvas.width = image.naturalWidth
  const height = canvas.height = image.naturalHeight
  context.drawImage(image, 0, 0, width, height)

  const imageData = context.getImageData(0, 0, width, height)
  const pixelCount = width * height
  return createPixelArray(imageData.data, pixelCount, quality)
}

function createPixelArray (pixels: ImageData['data'], pixelCount: number, quality: number) {
  const pixelArray = []

  for (let i = 0, offset, r, g, b, a; i < pixelCount; i = i + quality) {
    offset = i * 4
    r = pixels[offset + 0]
    g = pixels[offset + 1]
    b = pixels[offset + 2]
    a = pixels[offset + 3]

    // If pixel is mostly opaque and not white
    if (typeof a === 'undefined' || a >= 125) {
      if (!(r > 250 && g > 250 && b > 250)) {
        pixelArray.push([r, g, b])
      }
    }
  }
  return pixelArray
}
