import tinycolor from 'tinycolor2'
import { getPalette } from './palette'

export function getForegroundColor (colorHex: string, bgColorHex: string = colorHex, steps: number = 3) {
  if (--steps < 0) {
    return colorHex
  }

  const fg = tinycolor(colorHex)
  const bg = tinycolor(bgColorHex)

  const lightBg = bg.isLight()

  const color = lightBg
    ? fg.saturate(2).darken(10)
    : fg.desaturate(10).lighten(20)

  const contrastRatio = lightBg ? 2 : 3

  if (tinycolor.readability(color, bg) < contrastRatio) {
    return getForegroundColor(color.toHexString(), bg.toHexString(), steps)
  }

  return color.toHexString()
}

export function getPaletteFromImage (img: HTMLImageElement) {
  return getPalette(img).map(([r, g, b]) => tinycolor({ r, g, b }).toHexString())
}

export function getTinyColorsFromImage (img: HTMLImageElement) {
  return getPalette(img).map(([r, g, b]) => tinycolor({ r, g, b }))
}
