import { truncate as originalTruncate } from 'lodash-es'

export { capitalize, kebabCase, uniqueId } from 'lodash-es'

export function truncate (words?: string, options?: Parameters<typeof originalTruncate>[1]) {
  return originalTruncate(words || '-', { length: 50, omission: '…', ...options })
}

const SMALL_WORDS = /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i
const TOKENS = /[^\s:–—-]+|./g
const WHITESPACE = /\s/
const IS_MANUAL_CASE = /.(?=[A-Z]|\..)/
const ALPHANUMERIC_PATTERN = /[A-Za-z0-9\u00C0-\u00FF]/

export function titleCase (input: string, capitalizeFirstWord = false): string {
  let result = ''
  let m: RegExpExecArray | null

  // tslint:disable-next-line
  // eslint-disable-next-line no-cond-assign
  while ((m = TOKENS.exec(input)) !== null) {
    const { 0: token, index } = m

    if (
      (
        // Ignore already capitalized words.
        !IS_MANUAL_CASE.test(token)
        // Ignore small words except at beginning or end.
        && (!SMALL_WORDS.test(token))
        // Ignore URLs.
        && (input.charAt(index + token.length) !== ':' || WHITESPACE.test(input.charAt(index + token.length + 1)))
      )
        || (capitalizeFirstWord && index === 0)
    ) {
      // Find and uppercase first word character, skips over *modifiers*.
      result += token.replace(ALPHANUMERIC_PATTERN, m => m.toUpperCase())
      continue
    }

    result += token
  }

  return result
}
