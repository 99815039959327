import type GiftTemplate from '@consumer/types/GiftTemplate'
import { titleCase } from '@consumer/helpers/string'
import { getForegroundColor } from '@consumer/helpers/color'
import { v3WithImageBg } from '@consumer/logic/v3'
export * from '@consumer/logic/v3'

// Public: Returns the card text in v3 giftlys.
export function getTagline (gift: GiftTemplate) {
  return gift.giftlyMetadata?.tagline
}

// Public: Come up with some default recommended phrases to use in the card text in v3 giftlys.
export function generateDefaultTaglines (gift: GiftTemplate) {
  const taglines = []

  let defaultTagline = ''

  const place = gift.places?.[0]

  if (!place?.name) {
    defaultTagline = 'Any Place'
  }
  else {
    defaultTagline = !place.placeType || place.placeType === 'general' ? titleCase(place.name, true) : place.name
  }

  if ((gift.places?.length ?? 0) > 1) {
    taglines.push(`${defaultTagline} and ${gift.places!.length - 1} other place${gift.places!.length > 2 ? 's' : ''}`)
    taglines.push(`${defaultTagline} and ${gift.places!.length - 1} more`)
  }
  else {
    taglines.push(defaultTagline)
  }

  return taglines.concat([
    'Treat Yo Self',
    'A Special Gift, Just For You',
    'Enjoy A Special Treat',
  ])
}

// Public: Comes up with a short phrase to use in the card text in v3 giftlys.
export function generateDefaultTagline (gift: GiftTemplate) {
  return generateDefaultTaglines(gift)[0]
}

// Public: Sets the card text used in v3 giftlys.
export function setTagline (gift: GiftTemplate, tagline?: string) {
  gift.giftlyMetadata ||= {}
  if (tagline) {
    gift.giftlyMetadata.tagline = tagline
    gift.giftlyMetadata.isCustomTagline = true
  }
  else {
    gift.giftlyMetadata.tagline = generateDefaultTagline(gift)
    gift.giftlyMetadata.isCustomTagline = false
  }
}

export function setTaglineUnlessCustom (gift: GiftTemplate) {
  if (!gift.giftlyMetadata?.isCustomTagline || (gift.places?.length ?? 0) <= 1) {
    setTagline(gift)
  }
}

export function isCurrentMaskColor (gift: GiftTemplate, color: string) {
  return color === gift.giftlyMetadata?.maskColor
}

export function setMaskColor (gift: GiftTemplate, color: string) {
  gift.giftlyMetadata ||= {}
  gift.giftlyDesignName = v3WithImageBg
  gift.giftlyMetadata.maskColor = color
  gift.giftlyMetadata.logoColor = getForegroundColor(color)
  gift.giftlyMetadata.backgroundColor = undefined
}
