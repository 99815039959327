import GAlertModal from '@consumer/components/GAlertModal.vue'
import GConfirmModal from '@consumer/components/GConfirmModal.vue'
import { mergeProps, type AsyncComponentLoader } from 'vue'
import { addModal, removeModals, type ModalComponent, type BaseModal } from '@consumer/stores/modals'
import { uniqueId } from '@consumer/helpers/string'
import { api } from '@corp/services/progress'
import { notifyError } from '@shared/errors'
import type { DemoStep } from '@consumer/modules/shared/HowGiftlyWorksModal.vue'
import type { CustomizerModalProps } from '@consumer/modules/customizer/CustomizerModal.vue'
import { trackConversion } from '@shared/tracking'
import type { MultiplaceGift } from '@consumer/types/serializers'
import MultiPlaceGiftIdeasApi from '@consumer/api/MultiPlaceGiftIdeasApi'

interface ModalArgs {
  name?: string
  header?: any
  content: any
  props?: any
}

interface AlertModalArgs extends ModalArgs {
  danger?: boolean
  onClose?: Function
}

interface ConfirmModalArgs extends ModalArgs {
  onConfirm?: Function
  onCancel?: Function
}

type ComponentPromise<T> = ReturnType<AsyncComponentLoader<T>>

// Public: Adds a new modal to the ModalManager with the specified configuration.
//
// Returns the opened modal id.
export function openModal<P> (
  component: ModalComponent<P>,
  modal: Omit<BaseModal, 'component'>,
) {
  return addModal({ component, ...modal })
}

// Public: Opens a modal component that wasn't pre-bundled.
//
// NOTE: By default the modal component path is used to create a static id,
// which prevents duplicates.
//
// NOTE: Pass `id: undefined` in the options to allow several instances of the
// same modal to be opened at the same time.
//
// Returns a promise that resolves to the opened modal id.
export function asyncOpenModal<P> (
  componentPromise: ComponentPromise<ModalComponent<P>>,
  { id, ...attrs }: BaseModal<P>['attrs'] & { id?: string } = {},
) {
  api?.startNavigation()
  return componentPromise
    .then((component) => {
      openModal((component as any).default || component, { attrs, id })
    })
    .catch((error) => {
      notifyError(error)
      openErrorAlertModal({ content: 'An unexpected error occurred, please try reloading the page' })
    })
    .finally(() => api?.finishNavigation())
}

export const openAlertModal = ({ name, header, content, danger = false, props = {}, onClose }: AlertModalArgs) => {
  // Cleanup previous alert modal if it exists
  removeModals('alert')

  addModal({
    component: GAlertModal,
    type: 'alert',
    attrs: mergeProps({
      name: name ?? uniqueId('modal-service-alert-'),
      danger,
    }, props ?? {}),
    eventListeners: {
      close: onClose,
    },
    slots: {
      header: header ? () => header : undefined,
      default: () => content,
    },
  })
}

export const openConfirmModal = ({ name, header, content, props = {}, onConfirm, onCancel }: ConfirmModalArgs) => {
  // Cleanup previous alert modal if it exists
  removeModals('confirm')

  addModal({
    component: GConfirmModal,
    type: 'confirm',
    attrs: mergeProps({
      name: name ?? uniqueId('modal-service-confirm-'),
    }, props ?? {}),
    eventListeners: {
      confirm: onConfirm,
      cancel: onCancel,
    },
    slots: {
      header: header ? () => header : undefined,
      default: () => content,
    },
  })
}

export function openDeleteModal ({ props = {}, ...options }: ConfirmModalArgs) {
  return openConfirmModal({ props: { confirmText: 'Delete', danger: true, ...props }, ...options })
}

export const openErrorAlertModal = ({
  name,
  header = 'Oops, something went wrong',
  content,
  onClose,
}: {
  name?: string
  header?: string
  content?: any
  onClose?: Function
}) => {
  openAlertModal({
    name: name ?? uniqueId('modal-service-error-alert-'),
    danger: true,
    header,
    content,
    onClose,
  })
}

export function openHowGiftlyWorksModal (props: { initialStep?: DemoStep; trackNavLinkClick?: boolean } = {}) {
  if (props.trackNavLinkClick) {
    trackConversion('how_it_works_nav_link')
  }
  return asyncOpenModal(import('@consumer/modules/shared/HowGiftlyWorksModal.vue'), {
    id: 'how-giftly-works-modal',
    ...props,
  })
}

export function openCustomizerModal (props: CustomizerModalProps) {
  return asyncOpenModal(import('@consumer/modules/customizer/CustomizerModal.vue'), {
    id: 'customizer-modal',
    ...props,
  })
}

export async function saveMultiplace (props: CustomizerModalProps, updatedMultiplaceGift: MultiplaceGift) {
  const giftlyIds = updatedMultiplaceGift.places.map(place => place.giftlyId)
  const response = await MultiPlaceGiftIdeasApi.updatePlaces({
    data: {
      internal_slug: updatedMultiplaceGift.internalSlug,
      placeIds: giftlyIds,
    },
  })

  if (response.success === true) {
    props.gift.places = response.newPlaces
    if (props.multiplaceGift) {
      props.multiplaceGift.places = response.newPlaces
    }
  }

  if (props.multiplaceGift && props.gift) {
    props.multiplaceGift.amounts = updatedMultiplaceGift.amounts
    props.gift.giftlyMetadata = toRaw(updatedMultiplaceGift.giftlyMetadata)
    props.multiplaceGift.giftlyMetadata = toRaw(updatedMultiplaceGift.giftlyMetadata)
    props.multiplaceGift.backgroundImageUrl = updatedMultiplaceGift.backgroundImageUrl
    props.gift.giftlyDesignName = updatedMultiplaceGift.giftlyMetadata.designName

    await MultiPlaceGiftIdeasApi.update({
      data: {
        internal_slug: updatedMultiplaceGift.internalSlug,
        amounts: updatedMultiplaceGift.amounts,
        giftlyMetadata: updatedMultiplaceGift.giftlyMetadata,
      },
    })
  }
}

export function openMultiplaceCustomizerModal (props: CustomizerModalProps) {
  return asyncOpenModal(import('@consumer/modules/customizer/CustomizerModal.vue'), {
    id: 'customizer-modal',
    onSaveMultiplace: async (updatedMultiplaceGift: MultiplaceGift) => {
      saveMultiplace(props, updatedMultiplaceGift)
    },
    ...props,
  })
}
