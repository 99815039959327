// JsFromRoutes CacheKey bfa747228669d1fa5eb1d263f9b2a39e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@consumer/services/api'

export default {
  profile: /* #__PURE__ */ definePathHelper('get', '/my-account/profile'),
  purchases: /* #__PURE__ */ definePathHelper('get', '/my-account/purchases'),
  billing: /* #__PURE__ */ definePathHelper('get', '/my-account/billing'),
  settings: /* #__PURE__ */ definePathHelper('get', '/my-account/settings'),
  publishedGiftsDrafts: /* #__PURE__ */ definePathHelper('get', '/my-account/published-gifts-drafts'),
  deleteDraft: /* #__PURE__ */ definePathHelper('delete', '/my-account/draft/:internal_slug'),
  resetPassword: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/reset_password'),
  updateInfo: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/update_info'),
  updateSettings: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/update_settings'),
  deleteAccount: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/delete_account'),
  checkUsername: /* #__PURE__ */ definePathHelper('post', '/consumer/my_account/check_username'),
}
